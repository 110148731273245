import React, { useState, useEffect } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Button, Flex, Text, Box } from 'rebass';
import fetch from 'isomorphic-unfetch';
import PacmanDivoora from '../images/pacman_divoora.inline.svg';

const DivooraButton = ({ placeID }) => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    const getUrl = async () => {
      try {
        const res = await fetch(
          `https://mangiami.herokuapp.com/api/divoora/${placeID}`,
        );
        const json = await res.json();
        const { url: divooraUrl } = json;
        setUrl(divooraUrl);
      } catch (err) {
        setUrl(null);
      }
    };
    if (placeID) {
      getUrl();
    }
  }, []);
  if (!url) {
    return null;
  }
  return (
    <Box px={2} py={1}>
      <OutboundLink href={url}>
        <Button fontSize={2} variant="outline">
          <Flex alignItems="center">
            <Text mr={2}>Ordina a domicilio con divoora.ch</Text>
            <PacmanDivoora width={18} height={18} />
          </Flex>
        </Button>
      </OutboundLink>
    </Box>
  );
};

export default DivooraButton;
