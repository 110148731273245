import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text, Link, Button } from 'rebass';
import slugify from 'slugify';
import { FaPhone } from 'react-icons/fa';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { Colors } from '../theme';
import ImageGrid from '../components/ImageGrid';
import Layout from '../components/Layout';
import RestaurantMap from '../components/RestaurantMap';
import {
  composeComparisons,
  sortByImageUrl,
  sortByPrice,
  sortBreakfastLast,
  sortBreakfastFirst,
  sortDessertLast,
} from '../utils';
import RestaurantLogo from '../components/RestaurantLogo';
import TradingHours from '../components/TradingHours';
import Socials from '../components/RestaurantSocial';
import OpenClosed from '../components/RestaurantOpenClose';
import SEO from '../components/SEO';
import DivooraButton from '../components/DivooraButton';
import { AiOutlineWarning } from 'react-icons/ai';

const QUERY = gql`
  query getRestaurant($id: ID!) {
    getRestaurant(id: $id) {
      id
      tradingHours
    }
  }
`;

const Restaurant = ({ pageContext: { restaurant } }) => {
  const [getTradingHours, { data, loading, error }] = useLazyQuery(QUERY, {
    variables: {
      id: restaurant.id,
    },
  });
  useEffect(() => {
    getTradingHours();
  }, [restaurant.id]);
  const { FoodImages } = restaurant;
  const imageGridItems = [
    ...FoodImages.filter(i => i.type === 'star').sort(
      composeComparisons([sortByPrice, sortByImageUrl]),
    ),
    ...FoodImages.filter(i => i.type === 'new').sort(
      composeComparisons([sortByPrice, sortByImageUrl]),
    ),
    ...FoodImages.filter(i => i.type === 'food').sort(
      composeComparisons([
        sortBreakfastLast,
        sortDessertLast,
        sortByPrice,
        sortByImageUrl,
      ]),
    ),
    ...FoodImages.filter(i => i.type === 'drink').sort(
      composeComparisons([sortBreakfastFirst, sortByPrice, sortByImageUrl]),
    ),
    ...FoodImages.filter(i => i.type === 'restaurant').sort(sortByImageUrl),
  ];
  // .concat(FoodImages.filter(i => i.type === 'restaurant').sort(sortByImageUrl));
  return (
    <Layout headerRight="categories">
      <SEO
        pageTitle={`${restaurant.name} - Mangiami`}
        pageDescription={`Foto. Prezzi. Informazioni. Serve altro per fare la tua scelta? ${restaurant.name} - ${restaurant.address}`}
        pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
        pageUrl={`/ristoranti/${slugify(restaurant.name.toLowerCase())}`}
      />
      <div style={{ backgroundColor: '#fafafa' }}>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <Flex flexWrap="wrap" mx={[0, 0, -2]} py={[0, 3]}>
            <Box px={[0, 0, 2]} width={[1, 1, 1 / 2]}>
              <Flex style={{ height: '100%' }} bg="#fff" p={[2, 3]}>
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  width={1}
                >
                  <Flex>
                    <Box px={2}>
                      <RestaurantLogo
                        size={[50, 80]}
                        imageUrl={restaurant.logo}
                      />
                    </Box>
                    <Box px={2} marginRight="auto">
                      <Flex flexDirection="column" justifyContent="center">
                        <Text lineHeight="1.35em" fontSize={[4, 5]}>
                          {restaurant.name}
                        </Text>
                        <Text color={Colors.GREY}>
                          {restaurant.cuisineType}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex px={2} py={[3, 0]} flexDirection="column">
                    <Socials
                      facebook={restaurant.facebook}
                      instagram={restaurant.instagram}
                      website={restaurant.website}
                    />
                    {restaurant.phone && (
                      <Box py={3}>
                        <Text>
                          <FaPhone size={16} style={{ marginRight: 5 }} />
                          {restaurant.phone}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                  {restaurant.bookable && (
                    <Flex flexDirection="column" justifyContent="flex-end">
                      <Box px={2} py={2}>
                        <Link href={`tel:${restaurant.phone}`}>
                          <Button fontSize={2} variant="primary">
                            Chiama e prenota un tavolo
                          </Button>
                        </Link>
                      </Box>
                    </Flex>
                  )}
                  <DivooraButton placeID={restaurant.placeID} />
                </Flex>
              </Flex>
            </Box>
            <Box px={[0, 0, 2]} width={[1, 1, 1 / 2]}>
              <Flex
                flexWrap="wrap"
                style={{ height: '100%' }}
                flexDirection="row"
              >
                <Box p={[3]} width={[1, 2 / 3]} bg="#fff">
                  <Flex
                    flexDirection="column"
                    style={{ height: '100%' }}
                    justifyContent="space-between"
                  >
                    {loading && <Text py={2}>Caricamento orari ...</Text>}
                    {error && <Text>Errore caricamento orari</Text>}
                    {data && data.getRestaurant && (
                      <>
                        <Box py={[2, 0]}>
                          <OpenClosed
                            tradingHours={data.getRestaurant.tradingHours}
                          />
                          <Flex mt={3} alignItems="center">
                            <AiOutlineWarning
                              size={18}
                              color={Colors.ORANGE}
                              style={{ marginRight: 5 }}
                            />
                            <Text color={Colors.ORANGE} fontSize={1}>
                              Periodo di riapertura: gli orari potrebbero
                              variare
                            </Text>
                          </Flex>
                        </Box>
                        <Box py={[2, 0]}>
                          <TradingHours
                            tradingHours={data.getRestaurant.tradingHours}
                          />
                        </Box>
                      </>
                    )}
                    <Box py={[2, 0]}>
                      <Link
                        color={Colors.BLACK}
                        href={`https://www.google.com/maps/dir/?api=1&destination=${restaurant.location.coordinates[1]},${restaurant.location.coordinates[0]}`}
                        target="_blank"
                        rel="noopener"
                      >
                        {restaurant.address}
                      </Link>
                    </Box>
                  </Flex>
                </Box>
                <Box className="hidden-xs" width={[1, 1 / 3]} bg="#fff">
                  <Flex
                    style={{ height: '100%' }}
                    width={1}
                    flexDirection="column"
                    alignSelf="flex-end"
                  >
                    <RestaurantMap
                      latitude={restaurant.location.coordinates[1]}
                      longitude={restaurant.location.coordinates[0]}
                      address={restaurant.address}
                    />
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Box pb={4}>
            <ImageGrid items={imageGridItems} />
          </Box>
          <Box pb={4}>
            <Text color={Colors.GREY} textAlign="center">
              I contenuti presenti sulla Piattaforma, in particolare le
              fotografie dei cibi e le schede dei ristoranti, sono pubblicati a
              titolo meramente informativo e senza garanzia, per cui alcuni
              cibi, bevande e/o servizi (incluso il prezzo) potrebbero non
              essere sempre aggiornati e/o disponibili.
            </Text>
          </Box>
        </div>
      </div>
    </Layout>
  );
};

Restaurant.propTypes = {
  pageContext: PropTypes.shape({
    restaurant: PropTypes.shape().isRequired,
  }).isRequired,
};

export default Restaurant;
