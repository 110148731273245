import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Card } from 'rebass';
import { Link } from 'gatsby';
import CardFood from './CardFood';

const ImageGrid = ({ items }) => (
  <Flex mx={[0, 0, -3]} flexDirection="row" flexWrap="wrap">
    {items.map(image => (
      <Box key={image.id} width={[1, 1 / 2, 1 / 3]} py={[2, 3]} px={[2, 3]}>
        <Card style={{ border: '1px solid #ddd', height: '100%' }} bg="#fff">
          {/* <Link style={{ textDecoration: 'none' }} key={image.id} to={`/foto/${image.id}`}> */}
          <CardFood
            imageUrl={image.imageUrl}
            title={image.title}
            price={image.price}
            description={image.description}
            type={image.type}
            dishOfTheDay={image.dishOfTheDay}
          />
          {/* </Link> */}
        </Card>
      </Box>
    ))}
  </Flex>
);

ImageGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ImageGrid;
