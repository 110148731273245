import React from 'react';
import { Flex, Box, Text } from 'rebass';
import { format } from 'date-fns';
import { Colors } from '../theme';
import { DAYS, DAYS_TEXT, formatTime } from '../utils';

const TradingHours = ({ tradingHours }) => (
  <Flex flexDirection="column">
    {DAYS.map((day, i) => {
      const isToday = format(new Date(), 'EEEE') === day;
      return (
        <Flex key={day} flexDirection="row">
          <Box>
            <Text
              fontWeight={isToday ? '500' : '400'}
              mr={15}
              color={isToday ? Colors.BLACK : Colors.GREY}
            >
              {DAYS_TEXT[i]}
            </Text>
          </Box>
          <Flex flexDirection="row" justifyContent="flex-end" flex={1}>
            {tradingHours && tradingHours[day] && tradingHours[day].length > 0 ? (
              tradingHours[day].map((timeRange, k) => (
                <Flex flexDirection="row" key={k}>
                  <Text
                    lineHeight={1.4}
                    textAlign="right"
                    key={`${day}_${k}`}
                    color={isToday ? Colors.BLACK : Colors.GREY}
                  >
                    {formatTime(timeRange)}
                  </Text>
                  {k + 1 < tradingHours[day].length && (
                    <Text lineHeight={1.4} mx={2}>
                      |
                    </Text>
                  )}
                </Flex>
              ))
            ) : (
              <Text
                lineHeight={1.4}
                style={{
                  color: isToday ? Colors.BLACK : Colors.GREY,
                }}
              >
                Chiuso
              </Text>
            )}
          </Flex>
        </Flex>
      );
    })}
  </Flex>
);

export default TradingHours;
