import React from 'react';
import { Flex, Box, Text } from 'rebass';
import { FaInstagram, FaFacebook, FaGlobe } from 'react-icons/fa';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Colors } from '../theme';

const Socials = ({ website, facebook, instagram }) => {
  const socials = [];
  if (website) {
    socials.unshift(
      <Box key="website" width={1 / 3}>
        <OutboundLink href={website}>
          <Text color={Colors.BLACK} key="website" title="Website" iconName="website">
            <FaGlobe size={20} style={{ marginRight: 5, marginBottom: -3 }} />
            Website
          </Text>
        </OutboundLink>
      </Box>,
    );
  } else {
    socials.push(<Box key="website" />);
  }
  if (instagram) {
    socials.unshift(
      <Box key="instagram" width={1 / 3}>
        <OutboundLink href={instagram}>
          <Text color={Colors.BLACK} key="instagram" title="Instagram" iconName="instagram">
            <FaInstagram size={20} style={{ marginRight: 5, marginBottom: -3 }} />
            Instagram
          </Text>
        </OutboundLink>
      </Box>,
    );
  } else {
    socials.push(<Box key="instagram" />);
  }
  if (facebook) {
    socials.unshift(
      <Box key="facebook" width={1 / 3}>
        <OutboundLink href={facebook}>
          <Text color={Colors.BLACK} key="facebook" title="Facebook" iconName="facebook">
            <FaFacebook size={20} style={{ marginRight: 5, marginBottom: -3 }} />
            Facebook
          </Text>
        </OutboundLink>
      </Box>,
    );
  } else {
    socials.push(<Box key="facebook" />);
  }
  if (website || facebook || instagram) {
    return (
      <Flex width={1} alignItems="center" flexDirection="row">
        {socials}
      </Flex>
    );
  }
  // no links to show
  return null;
};

export default Socials;
