import React from 'react';
import PropTypes from 'prop-types';
import { Card, Link } from 'rebass';
import { generateMapsUrl } from '../utils';

const RestaurantMap = ({ latitude, longitude }) => (
  <Link
    href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`}
  >
    <Card
      width={1}
      sx={{
        height: 340,
        backgroundImage: `url('${generateMapsUrl(latitude, longitude)}')`,
        backgroundSize: 'cover',
      }}
    />
  </Link>
);

RestaurantMap.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
};

export default RestaurantMap;
